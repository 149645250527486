import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DesktopOutlined,
  BarChartOutlined,
  ProfileOutlined,
  ReadOutlined,
  ShopOutlined,
  CommentOutlined,
  TeamOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/dashboard/count" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <BarChartOutlined />,
      label: "대시보드",
    },
    children: [
      {
        exact: true,
        path: "/count",
        sidebar: {
          label: "카운트 대시보드",
        },
        component: loadable(() => import("../pages/dashboard/CountList")),
      },
      {
        exact: true,
        path: "/sale",
        sidebar: {
          label: "매출 현황",
        },
        component: loadable(() => import("../pages/dashboard/sale/List")),
      },
      {
        exact: true,
        path: "/stats",
        sidebar: {
          label: "통계",
        },
        component: loadable(() => import("../pages/dashboard/stats/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "일반 회원 리스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        path: "/company",
        sidebar: {
          label: "기업 회원 리스트",
        },
        component: loadable(() => import("../pages/user/company/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/company/detail/:id",
        component: loadable(() => import("../pages/user/company/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/company/register",
        component: loadable(() => import("../pages/user/company/Register")),
      },
      {
        exact: true,
        path: "/withdrawal",
        sidebar: {
          label: "탈퇴 회원 리스트 (일반)",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        path: "/company/withdrawal",
        sidebar: {
          label: "탈퇴 회원 리스트 (기업)",
        },
        component: loadable(() => import("../pages/user/company/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    sidebar: {
      icon: <UserOutlined />,
      label: "주문 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "일반 주문 리스트",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/order/Register")),
      },
      {
        exact: true,
        path: "/subscribe",
        sidebar: {
          label: "구독 주문 리스트",
        },
        component: loadable(() => import("../pages/order/subscribe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/subscribe/register",
        component: loadable(() => import("../pages/order/subscribe/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/delivery/list/:type",
        component: loadable(() => import("../pages/order/delivery/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/delivery/basic/:id",
        component: loadable(() => import("../pages/order/delivery/BasicDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/delivery/subscribe/:id",
        component: loadable(() => import("../pages/order/delivery/SubscribeDetail")),
      },
      {
        exact: true,
        path: "/user",
        sidebar: {
          label: "구독 회원 리스트",
        },
        component: loadable(() => import("../pages/user/List")),
      },
    ],
  },

  {
    exact: true,
    path: "/diet",
    sidebar: {
      icon: <ProfileOutlined />,
      label: "식단관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "식단 카테고리 관리",
        },
        component: loadable(() => import("../pages/diet/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/:id",
        component: loadable(() => import("../pages/diet/category/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/register",
        component: loadable(() => import("../pages/diet/category/Register")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "식단 리스트",
        },
        component: loadable(() => import("../pages/diet/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/diet/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/diet/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined />,
      label: "상품관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "카테고리 리스트",
        },
        component: loadable(() => import("../pages/product/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/:id",
        component: loadable(() => import("../pages/product/category/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/register",
        component: loadable(() => import("../pages/product/category/Register")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "상품 리스트",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/product/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/product/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/recipe",
    sidebar: {
      icon: <ReadOutlined />,
      label: "레시피 관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "레시피 카테고리 관리",
        },
        component: loadable(() => import("../pages/recipe/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/:id",
        component: loadable(() => import("../pages/recipe/category/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/register",
        component: loadable(() => import("../pages/recipe/category/Register")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "자체 레시피 리스트",
        },
        component: loadable(() => import("../pages/recipe/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/recipe/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/recipe/Modify")),
      },
      {
        exact: true,
        path: "/company",
        sidebar: {
          label: "일반/기업 레시피 리스트",
        },
        component: loadable(() => import("../pages/recipe/company/List")),
      },
      {
        exact: true,
        path: "/download/request",
        sidebar: {
          label: "레시피 다운로드 기록",
        },
        component: loadable(() => import("../pages/recipe/download/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/consult",
    sidebar: {
      icon: <CommentOutlined />,
      label: "영양상담 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "상담 리스트",
        },
        component: loadable(() => import("../pages/consult/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/consult/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/contents",
    sidebar: {
      icon: <DesktopOutlined />,
      label: "컨텐츠 관리",
    },
    children: [
      {
        exact: true,
        path: "/banner/header",
        sidebar: {
          label: "헤더 배너 리스트",
        },
        component: loadable(() => import("../pages/contents/banner/header/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/header/detail/:id",
        component: loadable(() => import("../pages/contents/banner/header/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/header/register",
        component: loadable(() => import("../pages/contents/banner/header/Register")),
      },
      {
        exact: true,
        path: "/banner/careCenter/main",
        sidebar: {
          label: "케어센터 메인 배너 리스트",
        },
        component: loadable(() => import("../pages/contents/banner/careCenter/main/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/careCenter/main/detail/:id",
        component: loadable(() => import("../pages/contents/banner/careCenter/main/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/careCenter/main/register",
        component: loadable(() => import("../pages/contents/banner/careCenter/main/Register")),
      },
      {
        exact: true,
        path: "/banner/careCenter/sub",
        sidebar: {
          label: "케어센터 서브 배너 리스트",
        },
        component: loadable(() => import("../pages/contents/banner/careCenter/sub/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/careCenter/sub/detail/:id",
        component: loadable(() => import("../pages/contents/banner/careCenter/sub/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/careCenter/sub/register",
        component: loadable(() => import("../pages/contents/banner/careCenter/sub/Register")),
      },
      {
        exact: true,
        path: "/banner/market",
        sidebar: {
          label: "맛저마켓 메인 배너 리스트",
        },
        component: loadable(() => import("../pages/contents/banner/market/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/market/detail/:id",
        component: loadable(() => import("../pages/contents/banner/market/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/market/register",
        component: loadable(() => import("../pages/contents/banner/market/Register")),
      },
      {
        exact: true,
        path: "/diet",
        sidebar: {
          label: "식단표 리스트",
        },
        component: loadable(() => import("../pages/contents/diet/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/diet/detail/:id",
        component: loadable(() => import("../pages/contents/diet/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/diet/register",
        component: loadable(() => import("../pages/contents/diet/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/admin",
    sidebar: {
      icon: <TeamOutlined />,
      label: "관리자 관리",
    },
    children: [
      {
        exact: true,
        path: "/grade",
        sidebar: {
          label: "관리자 레벨 관리",
        },
        component: loadable(() => import("../pages/admin/grade/List")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "관리자 리스트",
        },
        component: loadable(() => import("../pages/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/admin/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/admin/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/etc",
    sidebar: {
      icon: <SettingOutlined />,
      label: "기타 관리",
    },
    children: [
      {
        exact: true,
        path: "/api",
        sidebar: {
          label: "배송 추적 API 관리",
        },
        component: loadable(() => import("../pages/etc/api/List")),
      },
      {
        exact: true,
        path: "/deliveryCompany",
        sidebar: {
          label: "택배사 관리",
        },
        component: loadable(() => import("../pages/etc/deliveryCompany/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/deliveryCompany/detail/:id",
        component: loadable(() => import("../pages/etc/deliveryCompany/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/deliveryCompany/register",
        component: loadable(() => import("../pages/etc/deliveryCompany/Register")),
      },
      {
        exact: true,
        path: "/disease",
        sidebar: {
          label: "대표질환 관리",
        },
        component: loadable(() => import("../pages/etc/disease/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/disease/detail/:id",
        component: loadable(() => import("../pages/etc/disease/Detail")),
      },
      {
        exact: true,
        path: "/terms",
        sidebar: {
          label: "약관 관리",
        },
        component: loadable(() => import("../pages/etc/terms/List")),
      },
      {
        exact: true,
        path: "/editor",
        sidebar: {
          label: "안내 관리",
        },
        component: loadable(() => import("../pages/etc/editor/List")),
      },
      {
        exact: true,
        path: "/footer",
        sidebar: {
          label: "FOOTER 관리",
        },
        component: loadable(() => import("../pages/etc/footer/List")),
      },
    ],
  },
];
