import axios from "axios";

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
customAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config.url === '/refresh/token' && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/";
        return
      } else if (
          error.response &&
          error.response.status === 401 &&
          !error.config._retry
      ) {
        if (!localStorage.getItem("token")) {
          localStorage.removeItem("token");
          window.location.href = "/";
        } else if (!localStorage.getItem("refresh_token")) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          window.location.href = "/";
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          window.location.href = "/";
        }
      }
      return Promise.reject(error);
    }
)
const useAxios = () => {
  return customAxios;
};

export default useAxios;
